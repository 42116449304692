import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Layout from 'view/layout/Layout';
import * as URoute from 'util/Route';

const AboutPage = withRouter(React.lazy(() => import('view/page/site/About')));
const ContactPage = withRouter(React.lazy(() => import('view/page/site/Contact')));
const ErrorPage = withRouter(React.lazy(() => import('view/page/site/Error')));
const HomePage = withRouter(React.lazy(() => import('view/page/site/Home')));
const PrivacyPage = withRouter(React.lazy(() => import('view/page/site/Privacy')));
const TermsPage = withRouter(React.lazy(() => import('view/page/site/Terms')));

const AgentPage = withRouter(React.lazy(() => import('view/page/sector/Agent')));
const DeveloperPage = withRouter(React.lazy(() => import('view/page/sector/Developer')));

function App() {
    return (
        <Layout>
            <Switch>
                <Route path={URoute.url('home')} exact component={HomePage} />
                <Route path={URoute.url('ea')} exact component={AgentPage} />
                <Route path={URoute.url('dev')} exact component={DeveloperPage} />

                <Route path={URoute.url('about')} exact component={AboutPage} />
                <Route path={URoute.url('contact')} exact component={ContactPage} />

                <Route path={URoute.url('terms')} exact component={TermsPage} />
                <Route path={URoute.url('privacy')} exact component={PrivacyPage} />

                <Route component={ErrorPage} />
            </Switch>
        </Layout>
    );
}

export default withRouter(App);
